<template>
    <v-card flat style="padding: 40px 0 0 0; max-width :100%;">
        <v-card-text style="padding: 0;">
            <v-layout align-center>
                <label class="subtitle-1">{{adminData.login}}</label>
                <label class="subtitle-1 font-weight-bold black--text pointer"
                       style="margin-left: 40px;"
                       @click="isChangeEmailVisible = !isChangeEmailVisible"
                       v-if="adminData.isRoot">
                    Изменить</label>
            </v-layout>

            <v-layout style="margin-top: 15px; max-width :100%;">
                <v-slide-y-transition>
                    <email-form @success="onEmailChanged($event)" v-if="isChangeEmailVisible"/>
                </v-slide-y-transition>
            </v-layout>

            <v-layout align-center
                      style="margin-top: 10px;"
                      v-if="!adminData.isRoot">
                <label class="subtitle-1">{{adminData.name}}</label>
            </v-layout>

            <v-layout align-center>
                <label class="subtitle-1 font-weight-bold black--text pointer"
                       @click="isVisiblePasswordModal = true"
                       style="margin-top: 20px;">
                    Изменить пароль</label>
            </v-layout>

            <v-layout align-center>
                <v-btn text
                       color="secondary"
                       @click="$router.history.push({name: 'login'})"
                       style="width: 200px; margin: 40px 0 0 20px; border-radius: 8px; height: 40px;">
                    Выйти из аккаунта
                </v-btn>
            </v-layout>
        </v-card-text>

        <v-dialog v-model="isVisiblePasswordModal"
                  max-width="650"
                  overlay-color="white"
                  overlay-opacity="0.5"
                  :persistent="editLoading">
            <password-form :data="adminData"
                           @close="isVisiblePasswordModal = false"
                           @setLoading="editLoading = $event"
                           @showSnackbar="showSnackBar($event)"
                           :loading="editLoading"
                           :is-visible="isVisiblePasswordModal"/>
        </v-dialog>

        <v-snackbar
                v-model="snackbar"
                color="secondary"
                top
                :timeout="1000"
        >
            {{ snackText }}
        </v-snackbar>
    </v-card>
</template>

<script>
    import PasswordForm from "../profile/PasswordForm";
    import EmailForm from "./EmailForm";

    export default {
        name: "ProfileForm",
        components: {
            EmailForm,
            PasswordForm},
        data: () => ({
            isVisiblePasswordModal: false,
            editLoading: false,
            snackText: '',
            snackbar: false,
            isChangeEmailVisible: false,
            showEmailChangedModal: false
        }),
        computed: {
            isMobile() {
                return this.$store.state.isMobile
            },
            adminData() {
                return this.$store.state.adminData
            }
        },
        methods: {
            showSnackBar(text) {
                this.snackText = text;
                this.snackbar = true
            },
            onEmailChanged(text){
                this.isChangeEmailVisible = false;
                this.showSnackBar(text)
            }
        }
    }
</script>

<style scoped>
    .pointer {
        cursor: pointer;
    }

    .pointer:hover {
        color: grey !important;
    }
</style>