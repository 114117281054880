<template>
    <v-form ref="form"
            style="width: 500px; margin-bottom: 40px; max-width :100%;"
            :style="{'padding-left': isMobile ? '0' : '10px'}">
        <v-card class="elevation-0">
            <v-card-title class="title font-weight-black">
                Новый адрес
            </v-card-title>
            <v-card-text>
                <v-layout style="margin-top: 10px;"
                          :align-center="!isMobile"
                          :column="isMobile">
                    <label class="subtitle-1 field-label">Пароль</label>
                    <v-text-field
                            v-model="password"
                            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="rules.passwordRules"
                            validate-on-blur
                            color="secondary"
                            @input="invalidPassword = false"
                            style="max-width: 350px;"
                            :type="show ? 'text' : 'password'"
                            @click:append="show = !show"
                    />
                </v-layout>

                <v-layout style="margin-top: -5px;"
                          :align-center="!isMobile"
                          :column="isMobile">
                    <label class="subtitle-1 field-label">Новый адрес почты</label>
                    <v-text-field
                            v-model="email"
                            :rules="rules.emailRules"
                            validate-on-blur
                            @input="emailIsTaken = false"
                            color="secondary"
                            style="max-width: 350px;"
                    />
                </v-layout>
            </v-card-text>
            <v-card-actions>
                <v-layout column :style="{'padding-left': isMobile ? '0' : '180px'}">
                    <v-btn color="secondary"
                           @click="saveData"
                           style="border-radius: 8px; height: 45px;">
                        Отправить подтверждение
                    </v-btn>
                    <label class="subtitle-1" style="line-height: 21px; margin-top: 15px;">
                        Адрес будет изменен после подтверждения по ссылке из письма.</label>
                </v-layout>
            </v-card-actions>
        </v-card>
    </v-form>
</template>

<script>
    import ValidationMixin from "../../mixins/ValidationMixin";

    let shajs = require('sha.js');

    export default {
        name: "email-form",
        mixins: [ValidationMixin],
        data: () => ({
            password: '',
            email: '',
            show: false,
            loading: false
        }),
        computed: {
            isMobile() {
                return this.$store.state.isMobile
            }
        },
        methods: {
            saveData() {
                if (this.$refs.form.validate()) {
                    this.loading = true;
                    const params = {
                        headers: {token: localStorage.getItem('token')}
                    };
                    const postData = {
                        login: this.email,
                        passwordHash: shajs('sha256').update(this.password).digest('hex')
                    };

                    this.$http.post("/profile/email", postData, params)
                        .then(response => {
                            this.$emit('success', 'Письмо с подтверждением отправлено на указанную почту')
                        })
                        .catch(error => {
                            console.log(error)
                            switch (error.response.status) {
                                case 406:
                                case 401:
                                    this.$router.history.push({name: 'login'});
                                    break;
                                case 409:
                                    this.emailIsTaken = true;
                                    this.$nextTick().then(() => {
                                        this.$refs.form.validate()
                                    });
                                    break;
                                case 403:
                                    this.invalidPassword = true;
                                    this.$nextTick().then(() => {
                                        this.$refs.form.validate()
                                    });
                                    break;
                                default:
                                    console.log('error');
                                    break;
                            }
                        })
                }
            }
        }
    }
</script>

<style scoped>
    .input-margin {
        margin-top: -15px;
    }

    .field-label {
        width: 180px;
    }
</style>