<template>
    <v-form ref="form">
        <v-card style="padding: 20px 20px 30px 20px;">
            <v-card-title>
                <v-layout justify-space-between align-center>
                    <label class="display-1 font-weight-bold" style="word-break: break-word;">
                        Новый пароль
                    </label>
                    <v-btn @click="$emit('close')"
                           :disabled="loading"
                           large
                           icon>
                        <v-icon large>mdi-close</v-icon>
                    </v-btn>
                </v-layout>
            </v-card-title>
            <v-card-text style="margin-top: 20px;">
                <v-layout justify-start
                          :align-center="!isMobile"
                          :column="isMobile">
                    <label class="subtitle-1 field-label">Почта</label>
                    <label class="subtitle-1">{{data.login}}</label>
                </v-layout>

                <v-layout style="margin-top: 10px;"
                          :align-center="!isMobile"
                          :column="isMobile">
                    <label class="subtitle-1 field-label">Новый пароль</label>
                    <v-text-field
                            v-model="password"
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="rules.passwordRules"
                            validate-on-blur
                            color="secondary"
                            style="max-width: 300px;"
                            :type="show1 ? 'text' : 'password'"
                            name="input-10-1"
                            @click:append="show1 = !show1"
                    />
                </v-layout>

                <v-layout style="margin-top: -5px;"
                          :align-center="!isMobile"
                          :column="isMobile">
                    <label class="subtitle-1 field-label">Повторите пароль</label>
                    <v-text-field
                            v-model="repeatPassword"
                            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="rules.repeatPasswordRules"
                            validate-on-blur
                            color="secondary"
                            @input="validatePassword"
                            style="max-width: 300px;"
                            :type="show2 ? 'text' : 'password'"
                            name="input-10-1"
                            @click:append="show2 = !show2"
                    />
                </v-layout>
            </v-card-text>

            <v-card-actions>
                <v-spacer/>
                <v-btn :loading="loading"
                       @click="saveData"
                       color="secondary"
                       style="width: 190px; height: 45px; border-radius: 8px;">Сохранить пароль
                </v-btn>
                <v-spacer/>
            </v-card-actions>
        </v-card>
    </v-form>
</template>

<script>
    import ValidationMixin from "../../mixins/ValidationMixin";
    let shajs = require('sha.js');

    export default {
        name: "PasswordForm",
        mixins: [ValidationMixin],
        props: {
            loading: {
                type: Boolean,
                required: true
            },
            data: {
                type: Object,
                required: true
            },
            isVisible: {
                type: Boolean,
                required: true
            }
        },
        data: () => ({
            password: '',
            repeatPassword: '',
            show1: false,
            show2: false,
            repeatError: false,
            isDisabledError: false
        }),
        computed: {
            isMobile() {
                return this.$store.state.isMobile
            },
        },
        watch: {
            isVisible(to) {
                this.password = '';
                this.repeatPassword = '';
                if (this.$refs.form)
                    this.$refs.form.resetValidation();
                this.isDisabledError = false;
            }
        },
        methods: {
            saveData() {
                this.validatePassword();
                if (this.$refs.form.validate()) {
                    this.$emit('setLoading', true);
                    const params = {headers: {token: localStorage.getItem('token')}};
                    let postData = {
                        passwordHash: shajs('sha256').update(this.password).digest('hex')
                    };

                    this.$http.post("/profile/edit", postData, params)
                        .then(response => {
                            setTimeout(() => {
                                this.$emit('showSnackbar', 'Пароль успешно изменён');
                                this.$emit('close')
                            }, 500)
                        }).catch(error => {
                        console.log(error);
                        switch (error.response.status) {
                            case 406:
                            case 401:
                                this.$router.history.push({name: 'login'});
                                break;
                            case 403:
                                this.emailIsTaken = true;
                                this.$nextTick().then(() => {
                                    this.$refs.form.validate()
                                });
                                break;
                            default:
                                console.log('error');
                                break;
                        }
                    }).finally(() => {
                        setTimeout(() => {
                            this.$emit('setLoading', false)
                        }, 500)
                    })
                }
            },
            validatePassword() {
                if (this.password !== this.repeatPassword) {
                    this.repeatError = true;
                    this.$nextTick().then(() => {
                        this.$refs.form.validate()
                    })
                } else this.repeatError = false
            }
        }
    }
</script>

<style scoped>
    .input-margin {
        margin-top: -15px;
    }

    .field-label {
        width: 180px;
    }
</style>