<template>
    <v-layout class="content-layout" column>
        <label class="secondary--text display-1 font-weight-bold">Профиль</label>
        <profile-form/>
    </v-layout>
</template>

<script>
    import ProfileForm from "../components/profile/ProfileForm";
    export default {
        name: "ProfileView",
        components: {ProfileForm}
    }
</script>

<style scoped>

</style>